import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import App from './App.tsx'
import { ExternalRedirect } from './components/ExternalRedirect.tsx'
import { NotFound } from './components/NotFound.tsx'
import './index.css'
import { initAnalytics } from './utils/analytics.ts'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
  tracePropagationTargets: ['localhost', import.meta.env.VITE_BACKEND_URL],
  environment: import.meta.env.VITE_ENV,
})

initAnalytics()

const jobsUrl =
  'https://supertrace.notion.site/About-Supertrace-AI-17635584ef37809a9197e1db23cb7014'

createRoot(document.getElementById('root')!).render(
  <Sentry.ErrorBoundary fallback={<div>Error</div>}>
    <StrictMode>
      <Router>
        <Routes>
          <Route path="/jobs" element={<ExternalRedirect url={jobsUrl} />} />
          <Route path="/careers" element={<ExternalRedirect url={jobsUrl} />} />

          {/* Other routes */}
          <Route path="/" element={<App />} />

          {/* 404 catch-all route - must be last */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </StrictMode>
  </Sentry.ErrorBoundary>
)
