import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
      }}
    >
      <div>404 | Page not found</div>
      <Link to="/">Go Home</Link>
    </div>
  )
}
